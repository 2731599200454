import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './global.css';
import { Header } from './header/Header';
import { Leaderboard } from './leaderboard/Leaderboard';
import { Calculator } from './calculator/Calculator';
import { LeaderboardResults } from './leaderboard/results/Leaderboard-Results';

function App() {
  return (
    <div className="root">
      <Router >
          <Header/>
          
          <Routes>
            <Route path="/leaderboard" element={ <Leaderboard />}/>
            <Route path="/calculator" element={ <Calculator />}/>
            <Route path="/leaderboard_previous" element={ <LeaderboardResults title="March" dataSource="march" ended={true} timestamp='31.03. at 23:59 PM CEST'/>}/>
            <Route path="*" element={ <Leaderboard />}/>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
