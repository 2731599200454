import './index.scss';
import { Link, useLocation} from 'react-router-dom';


export const Header = () => {
    const useReactElement = (route: string, title:string, disabled?: Boolean) => {
        let category = 'inactive';
        const path = useLocation().pathname;
        if(path === route) {
            category='active';
        }
        if(disabled) {
            category='disabled';
            return <li className='disabled'>{title}</li>
        }
        if(path === "/" && route === "/leaderboard") category='active';

        return (
            <Link to={route}>
                <li className={category}>
                    {title}
                </li>
            </Link>
        )
    }

    return (
        <header className="header">
            <div className="routes">
                <ul>
                    {useReactElement('/leaderboard', 'Leaderboard')}
                    {useReactElement('/calculator', 'XP Calculator')}
                    <a target='_blank' href='https://www.youtube.com/@budi_cs_yt' rel="noreferrer">
                        <li className='inactive'>Youtube (NEW)</li>
                    </a>
                    {useReactElement('/coming_soon', 'Coming soon 🤫', true)}
                </ul>
            </div>
        </header>
    );
}